
<ion-header>
  <ion-toolbar class="header">
    <ion-title>Alert Detail</ion-title>
    <ion-buttons slot="end">
      <ion-button color="light" (click)="dismissModal()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

    <ion-content>
      <div class="report-map" id="alertMap{{alertId}}"></div>
      <ion-card *ngIf="viewModel.alert">
        <ion-item>
          <img [src]="getAlertIcon(viewModel.alert)" slot="start"/>
          <ion-label>
           <h3 *ngIf="viewModel.alert.title">{{viewModel.alert.title}}</h3>
           <h3 *ngIf="!viewModel.alert.title">{{viewModel.alert.alertTypeName}}</h3>
           <p>{{viewModel.alert.address}}</p>
           <p>{{viewModel.alert?.addressFormatted}}</p>
           <p class="alert-description">{{getDateTimeFormatted(viewModel.alert.reportedOn, 'LT dddd, MMMM dd, yyyy')}}</p>
           <p class="alert-description">{{viewModel.alert.validationsCount}} People Affected</p>
           <p *ngIf="viewModel.alert.severity" class="alert-description">Severity: <span class="priority-value" [ngClass]="{'routine': viewModel.alert.analystAlertPriorityId == 1, 'priority': viewModel.alert.analystAlertPriorityId == 2, 'immediate': viewModel.alert.analystAlertPriorityId == 3, 'critical': viewModel.alert.analystAlertPriorityId == 4 }">{{viewModel.alert.analystAlertPriorityId}}</span></p>
           <p *ngIf="viewModel.alert.priority" class="alert-description">{{viewModel.alert.priority}}</p>
          </ion-label>
        </ion-item>

        <ion-card-content class="alert-description">
          {{viewModel.alert.description || '-'}}

          <ion-list>
            <ion-item>
              <ion-label>
              <h3>Impact Rating</h3>
              <p class="alert-description">{{viewModel.alert.impactRating || '-'}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Proximity StandOff</h3>
              <p class="alert-description">{{viewModel.alert.proximityStandOff || '-'}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Date Of Event</h3>
              <p class="alert-description">{{getDateTimeFormatted(viewModel.alert.reportedOn, 'dddd, MMMM dd, yyyy')}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Time Of Event</h3>
              <p class="alert-description">{{getDateTimeFormatted(viewModel.alert.reportedOn, 'HH:mm')}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Country</h3>
              <p class="alert-description">{{viewModel.address[0]?.country ? viewModel.address[0]?.country : viewModel?.alert?.country ||  '-'}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Destination</h3>
              <p class="alert-description">{{viewModel.address[0]?.city ? viewModel.address[0]?.city : viewModel?.alert?.city || '-'}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Latitude</h3>
              <p class="alert-description">{{viewModel.address[0]?.lat ? viewModel.address[0]?.lat : viewModel?.alert?.latitude ?
                viewModel?.alert?.latitude : viewModel?.alert?.location? viewModel?.alert?.location.k : '-'
              }}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Longitude</h3>
              <p class="alert-description">{{viewModel.address[0]?.long ? viewModel.address[0]?.long : viewModel?.alert?.longitude ?
                  viewModel?.alert?.longitude : viewModel?.alert?.location? viewModel?.alert?.location.i : '-'

                }}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Source</h3>
              <p class="alert-description">{{viewModel.alert.sourceName || '-'}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Source Reliability</h3>
              <p class="alert-description">{{viewModel.alert.confidenceRating || '-'}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Accuracy Rating</h3>
              <p class="alert-description">{{viewModel.alert.accuracy }}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Priority</h3>
              <p class="alert-description">{{viewModel.alert.priority}}</p>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>
              <h3>Tags</h3>
              <p class="alert-description">{{viewModel.alert.tags || '-'}}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>



        <!-- <div class="report-buttons" *ngIf="viewModel.alert">
            <button class="green" *ngIf="viewModel.alert.canValidate" (click)="validate()">Validate Alert</button>
            <button pButton class="ui-button-secondary" *ngIf="viewModel.alert.canThank" (click)="thank()">Thank Scout</button>
        </div> -->

    </ion-content>

import { Injectable } from '@angular/core';
import { inject } from "@angular/core";
import { ToastController, AlertController, LoadingController } from '@ionic/angular';


export enum MessageType{
  Error = 'danger',
  Success = 'success',
  Warning = 'warning',
  Info = 'info'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private toastController: ToastController = inject(ToastController);
  private alertController: AlertController = inject(AlertController);
  private loadingController: LoadingController = inject(LoadingController);
  loader: HTMLIonLoadingElement | undefined;

  constructor() {
    this.inItLoading();
  }

  public presentToast = async (message: string, type = MessageType.Error) => {

    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: "bottom",
      color: type,
      animated: true,
      mode: 'ios',
      cssClass: 'toasts-bottom',
    });
    await toast.present();

  }



  public alert = async (message: string, header: string = 'Alert', cbPositive: Function, cbNegative: Function) => {
    let alertButtons = [
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          cbNegative(alert);

        },
      },
      {
        text: 'OK',
        role: 'confirm',
        handler: () => {
          cbPositive(alert);


        },
      },
    ]
    const alert = await this.alertController.create({
      header,
      message,
      buttons: alertButtons,

    });
    await alert.present();
  }

  // async showLoading() {
  //   const loading =
  //   loading.present();
  // }

  // async closeLoading() {
  //   const loading =

  //   loading.present();
  // }


  async inItLoading(message?: string) {
    this.loader = await this.loadingController.create({
      message,
      mode: 'ios',
      cssClass: 'loading',
      animated: true,
      keyboardClose: false

    });
  }
  async showLoading() {

    if(this.loader){
      this.loader.present();
    }
    return;
  }

  async closeLoading() {
    if(this.loader){
      this.loader.dismiss();
    }
    return;
  }




}

import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthenticationService } from './authentication-service.service';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router,private storage: Storage) { }
  async canLoad() {
    //localStorage.create();
    const token = localStorage. getItem('accessToken');
    if(token){
      AuthenticationService.authenticationToken = token;
      return true;
    }
    else{
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}

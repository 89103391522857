/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettingsService } from './app-settings-service.service';
import { EventService } from './event.service';
import { LocalizationService } from './localization-service.service';
import { ApiClientService } from './api-client.service';


@Injectable()
export class AuthenticationService {

  public static authenticationSuccessEvent = 'authentication:success';
  public static tokenRefreshSuccessEvent = 'tokenRefreshSuccessEvent:success';
  public static logoutSuccessEvent = 'logout:success';
  private appSettingsService: AppSettingsService;
  static authenticationToken: string = null;
  constructor(public apiClient: HttpClient, private eventService: EventService, private router: Router, appSettingsService: AppSettingsService, private localizationService: LocalizationService) {
    this.apiClient = apiClient;
    this.appSettingsService = appSettingsService;
  }

  public recoverUsername(email: string) {
    return this.apiClient.post('api/account/recoverusername', JSON.stringify({ email }));
  }

  public resetPassword(username: string) {
    return this.apiClient.post('api/account/resetpassword', JSON.stringify({ username }));
  }

  public changePassword(current_pw: string, new_pw: string) {
    return this.apiClient.post('api/account/resetpassword', JSON.stringify({ CurrentPassword: current_pw, NewPassword: new_pw }));
  }

  handleError(error) {

    return Observable.throw(error.json() || 'Server error');
  }

  public getDefaultHeaders() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    return headers;
  }

  public requestToken(username, password, deviceId, deviceType) {

    const urlEncodedUsername = encodeURIComponent(username);
    const urlEncodedPassword = encodeURIComponent(password);
    const urlEncodedDeviceType = encodeURIComponent(deviceType);
    const urlEncodedDeviceId = encodeURIComponent(deviceId).toString();
    const urlEncodeFlag = encodeURIComponent(this.appSettingsService.isAppTravelRecon().toString());
    return this.apiClient.post('oauth/token', 'grant_type=password&username=' + urlEncodedUsername + '&password=' + urlEncodedPassword + '&devicetype=' + urlEncodedDeviceType + '&deviceid=' + urlEncodedDeviceId + '&isTravelRecon=' + urlEncodeFlag);
  }

  public refreshToken() {
    const refresh_token = encodeURIComponent(this.getRefreshToken());
    return this.apiClient.post('oauth/token', 'grant_type=refresh_token&refresh_token=' + refresh_token);
  }
  static isAuthenticated(): boolean {
    return !!AuthenticationService.authenticationToken;
  }

  public isCurrentUserAuthenticated(): boolean {
    //todo: define appropriate authenticaiton requirements here
    return AuthenticationService.authenticationToken && AuthenticationService.authenticationToken.length > 0;
  }

  public getAccessToken(): any {
    return localStorage.getItem('accessToken');
  }

  public getKeepSigned(): any {
    return localStorage.getItem('keepSigned');
  }

  public getRefreshToken(): any {
    return localStorage.getItem('refreshToken');
  }


  public authenticateUser(accessToken: string, keepSigned: boolean, refresh_token: string): boolean {
    AuthenticationService.authenticationToken = accessToken;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refresh_token);

    const isCurrentUserAuthenticated = this.isCurrentUserAuthenticated();
    this.eventService.publishData({ eventName: AuthenticationService.authenticationSuccessEvent, isAuthenticated: isCurrentUserAuthenticated });
    this.localizationService.initLabels().subscribe(() => { });

    this.router.navigateByUrl('/tabs', { replaceUrl: true });
    return isCurrentUserAuthenticated;
  }


  public refreshUser(accessToken: string, keepSigned: boolean, refresh_token: string, redirectPath = ''): boolean {
    AuthenticationService.authenticationToken = accessToken;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refresh_token);
    const isCurrentUserAuthenticated = this.isCurrentUserAuthenticated();
    this.eventService.publishData({ eventName: AuthenticationService.tokenRefreshSuccessEvent, isAuthenticated: isCurrentUserAuthenticated });
    this.localizationService.initLabels().subscribe(() => { });
    //this.router.navigateByUrl ('/tabs'+redirectPath,{replaceUrl: true});
    return isCurrentUserAuthenticated;
  }

  public logout() {
    this.router.navigateByUrl('/login', { replaceUrl: true });
    AuthenticationService.authenticationToken = '';
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    this.eventService.publishData(AuthenticationService.logoutSuccessEvent);
  }


  public registerFcmToken(token: string) {
    // NAM: use string only - return this.apiClient.post('api/mobile/account/platform/registerId',JSON.stringify({"key": token})); //JSON.stringify(token);
    return this.apiClient.post('api/mobile/account/registermobile', JSON.stringify(token));
  }




}

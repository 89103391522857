/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isLoading = false;
  gLoading: HTMLIonLoadingElement ;

  constructor(private loadingCtrl: LoadingController) {
    this.initLoadLoading();
   }

  async showLoading(msg) {
    // this.isLoading = true;
    // return await this.loadingCtrl.create({
    //   duration: 2000,
    //   message: msg,
    //   spinner: 'lines-sharp-small' //"bubbles" ｜ "circles" ｜ "circular" ｜ "crescent" ｜ "dots" ｜ "lines" ｜ "lines-sharp" ｜ "lines-sharp-small" ｜ "lines-small" ｜ null ｜ undefined
    // }).then(a => {
    //   a.present().then(() => {
    //
    //     if (!this.isLoading) {
    //       a.dismiss().then(() =>
    //     }
    //   });
    // });

   await this.loadLoading(true,msg);
  }

  async dismiss(message?) {
     await this.loadLoading(false,message);
     return true
  }

  async initLoadLoading(message='Please Wait...') {
    this.gLoading = await this.loadingCtrl.create({
      message,
      duration: 50000,
    });
    return 'created:initLoadLoading';
  }
  async loadLoading(toggle: boolean, message='Please Wait...') {
    if (toggle) {

      if (!this.gLoading) {

        const result = await this.initLoadLoading(message);
        await this.gLoading.present();
      }
      else{

        this.gLoading.message = message;
        await this.gLoading.present();
      }
    }
    else {
      if (this.gLoading) {

        await this.gLoading.dismiss();
      }
     await this.initLoadLoading(message);
    }
    return;
  }

}

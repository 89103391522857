/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings-service.service';
import { UserSettingsService } from './user-settings-service.service';
import { UserService } from './user.service';

@Injectable()
export class MapPinService {
    currentUserInfo: any;
    constructor(
        private appSettings: AppSettingsService,
        private userService: UserService
    ) {
    }

    //typings
    private w: any = window;

    public static alertPriorities: any = {
        low: 1,
        medium: 2,
        high: 3
    };

    public getContactFeature(pin: any, clickCallbackParams?: any): any {
        const coords = [pin.longitude, pin.latitude];

        return this.getFeature(
            coords,
            'contact-pin',
            { width: 18, height: 32 },
            { x: -9, y: -32 },
            '',
            clickCallbackParams
        );
    }

    public createObfuscatedPositionMarker(latLng: any, obfuscationRadius: number, map: any): any {
        return map.addLayer.circle(latLng, obfuscationRadius * 1000,
            {
                fillColor: '#001eff',
                color: '#001eff',
                weight: 3
            });
    }

    public clearCluster(map: any, clusterId: string): void {
        // if (map.getSource(clusterId)) {
        //     map.removeSource(clusterId);
        // }
        // if (map.getSource('point')) {
        //     map.removeSource('point');
        // // }
        // if (map.getLayer(clusterId + "-unclustered-points")) {
        //     map.removeLayer(clusterId + "-unclustered-points");
        // }

        // if (map.getLayer(clusterId + "-cluster")) {
        //     map.removeLayer(clusterId + "-cluster");
        // }

        // if (map.getLayer(clusterId + "-cluster-count")) {
        //     map.removeLayer(clusterId + "-cluster-count");
        // }
    }

    public createCluster(map: any, clusterId: string, featureCollection: any, color: string, radius = 10) {
        this.clearCluster(map, clusterId);
        //
        // map.getSource('lines').setData(featureCollection);
        if (map && !map.getSource(clusterId)) {
            map.addSource(clusterId, {
                type: 'geojson',
                data: featureCollection,
                cluster: true,
                clusterRadius: radius // Radius of each cluster when clustering points
            });
            if (!map.getSource('point')) {
                map.addSource('point', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [0, 0]
                                }
                            }
                        ]
                    }
                });
            };
        } else {
            //updating existing cluster data source
            map.getSource(clusterId).setData(featureCollection);
        }

        // Use the earthquakes source to create five layers:
        // One for unclustered points, three for each cluster category,
        // and one for cluster labels.
        if (featureCollection.features.length > 0) {
            const isPinForLocation = featureCollection.features[0].properties.iconUrl.startsWith('map_pin');
            const iconAnchor = isPinForLocation ? 'bottom' : 'center';
            if (!map.getLayer(clusterId + '-unclustered-points')) {
                map.addLayer({
                    id: clusterId + '-unclustered-points',
                    type: 'symbol',
                    source: clusterId,
                    filter: ['!has', 'point_count'],
                    layout: {
                        'icon-image': '{iconUrl}',
                        'icon-anchor': iconAnchor
                    }
                });
            }
            // map.addLayer({
            //     "id": "obfuscate",
            //     "type": "circle",
            //     "source": "earthquakes",
            //     "paint": {
            //         "circle-color": "#000000",
            //         "circle-radius": 50
            //     },

            // });
            if (!map.getLayer(clusterId + '-cluster')) {
                map.addLayer({
                    id: clusterId + '-cluster',
                    type: 'circle',
                    source: clusterId,
                    paint: {
                        'circle-color': color,
                        'circle-radius': 18
                    },
                    filter: ['>=', 'point_count', 0]
                });
            }
            // map.addLayer({
            //     'id': 'points',
            //     'type': 'symbol',
            //     'source': 'point',
            //     'layout': {
            //         'icon-image': 'gradient'
            //     }
            // });

            // Add a layer for the clusters' count labels
            if (!map.getLayer(clusterId + '-cluster-count')) {
                map.addLayer({
                    id: clusterId + '-cluster-count',
                    type: 'symbol',
                    source: clusterId,
                    layout: {
                        'text-field': '{point_count}',
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Bold'
                        ],
                        'text-size': 12
                    }
                });
            }
        }
    }

    public addWeatherOverlay(map: any) {

        map.addSource('aerisweather-radar', {
            type: 'raster',
            tiles: [
                'https://maps1.aerisapi.com/' + this.appSettings.getAriesToken() + '/radar/{z}/{x}/{y}/current.png',
                'https://maps2.aerisapi.com/' + this.appSettings.getAriesToken() + '/radar/{z}/{x}/{y}/current.png',
                'https://maps3.aerisapi.com/' + this.appSettings.getAriesToken() + '/radar/{z}/{x}/{y}/current.png',
                'https://maps4.aerisapi.com/' + this.appSettings.getAriesToken() + '/radar/{z}/{x}/{y}/current.png'
            ],
            tileSize: 256,
            attribution: '<a href=\'https://www.aerisweather.com/\'>AerisWeather</a>'
        });

        map.addLayer({
            id: 'radar-tiles',
            type: 'raster',
            source: 'aerisweather-radar',
            minzoom: 0,
            maxzoom: 22
        });
    }

    public removeWeatherOverlay(map: any) {
        map.removeLayer('radar-tiles');
        map.removeSource('aerisweather-radar');
    };

    public getCurrentPositionFeature(coords, clickCallbackParams?: any) {
        return this.getFeature(
            coords,
            'you-are-here',
            { width: 18, height: 32 },
            { x: -9, y: -32 },
            '',
            clickCallbackParams
        );
    }
    public getCurrentPositionFeatureObs(coords, clickCallbackParams?: any) {
        return this.getFeature(
            coords,
            'gradient',
            { width: 100, height: 100 },
            { x: -9, y: -32 },
            '',
            clickCallbackParams
        );
    }

    public getAlertFeature(pin, clickCallbackParams?: any): any {
        const alertFeature = this.getAlertByTypeFeature(pin, clickCallbackParams);

        if (pin.isEmergency) {
            const feature = this.getEmergencyFeature(pin, clickCallbackParams);

            setTimeout(() => {
                //marker.setIcon(pinIcon);
                //todo mapbox gl update styling
                pin.isEmergency = false;
            }, pin.emergencyExpiration * 1000 * 60);

            return feature;
        } else {
            return alertFeature;
        }
    }

    private getAlertByTypeFeature(pin, clickCallbackParams?: any): any {
        const coords = [pin.longitude, pin.latitude];
        let alertTypeCategoryId = 1;
        let alertPriorityId = 1;

        if (pin.alertTypeCategoryId) {
            alertTypeCategoryId = pin.alertTypeCategoryId;
        }

        if (pin.priority) {
            alertPriorityId = pin.priority;
        }

        return this.getFeature(
            coords,
            `icn_alert_${alertTypeCategoryId}_${alertPriorityId}`,
            { width: 28, height: 28 },
            { x: -10, y: -28 },
            '',
            clickCallbackParams
        );
    }

    public getEmergencyFeature(pin, clickCallbackParams?: any) {
        const coords = [pin.longitude, pin.latitude];

        return this.getFeature(
            coords,
            'map_pin_red',
            { width: 17, height: 28 },
            { x: -8, y: -28 },
            '',
            clickCallbackParams
        );
    }

    public getFeatureCollection(features: any): any {
        return {
            type: 'FeatureCollection',
            crs: { type: 'name', properties: {} },
            features
        };
    }

    public getFeature(coords, iconUrl?: any, iconSize?: any, iconOffset?: any, classes?: string, clickCallbackParams?: any): any {
        return {
            type: 'Feature',
            properties: {
                iconUrl,
                iconSize,
                iconOffset,
                clickCallbackParams
            },
            geometry: {
                type: 'Point',
                coordinates: coords
            }
        };
    }
}

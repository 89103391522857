/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { AuthenticationService } from './authentication-service.service';
import { AppSettings } from '../app-settings';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
const IMAGE_DIR = 'stored-images';

export interface LocalFile {
  name: string;
  path: string;
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class CameraPhotoService {

  constructor(private actionSheetCtrl: ActionSheetController,
    private plt: Platform, private http: HttpClient) { }

  async presentActionSheet(callBackPicture: (imageURI) => void, onlyFromCamera = false) {
    let _buttons = [];

    if (onlyFromCamera) {
      _buttons = [
        {
          text: 'Use Camera',
          handler: () => {
            this.getImage(CameraSource.Camera, callBackPicture);
          }
        }
      ]
    }
    else {
      _buttons = [
        {
          text: 'Load from Library',
          handler: () => {
            this.getImage(CameraSource.Photos, callBackPicture);
          }
        },
        {
          text: 'Use Camera',
          handler: () => {
            this.getImage(CameraSource.Camera, callBackPicture);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Image Source',
      buttons: _buttons
    });
    await actionSheet.present();
  }

  /**
   * Image picker based on selected Source choice
   *
   * @param sourceType
   */
  private async getImage(source: CameraSource, callBackPicture: (imageURI) => void) {
    // const options = {
    //   quality: 100,
    //   destinationType: this.camera.DestinationType.FILE_URI,
    //   correctOrientation: true,
    //   sourceType
    // };

    // this.camera.getPicture(options).then((imageData) => {
    //   const imageURI = imageData.split('?')[0];
    //   callBackPicture(imageURI);
    // }, (err) => {
    //   console.error(err);
    // });

    const imageURI = await Camera.getPhoto({
      quality: 100,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source
    });


    if (imageURI) {
      callBackPicture(imageURI);
      console.log('Picked Image URI ',imageURI);
    }
  }

  /**
   * TO upload image on Server using File Transfer Plugin.
   *
   * @param imageURI
   */
  public async uploadFile(imageURI, apiPath, params, callbackSuccess?: () => void, callbackFailed?: (error) => void) {
    //const fileTransfer: FileTransferObject = this.transfer.create();

    // const options: FileUploadOptions = {
    //   fileKey: 'image',
    //   fileName: imageURI.substr(imageURI.lastIndexOf('/') + 1),
    //   params,
    //   httpMethod: 'POST',
    //   headers: new Headers({
    //     Authorization: 'Bearer ' + AuthenticationService.authenticationToken
    //   })
    // };

    // fileTransfer.upload(imageURI, encodeURI(AppSettings.webApiRootUrl + apiPath), options)
    //   .then((data) => {
    //     if (callbackSuccess) {
    //       callbackSuccess();
    //     }
    //   }, (err) => {
    //     if (callbackFailed) {
    //       callbackFailed(err);
    //     }
    //   });

    console.log('uploadFile()', imageURI);
    const data: any = await this.saveImage(imageURI);
    //
    const response = await fetch(data);
    const blob = await response.blob();

    const formData = new FormData();
    formData.append('file', blob, new Date().getTime() + '.jpeg');

    this.http.post(encodeURI(AppSettings.webApiRootUrl + apiPath ), formData, {
      params,
      reportProgress: true,

    })
      .pipe(
        finalize(() => {
          //loading.dismiss();
        })
      )
      .subscribe((res: any) => {
        //if (res.success) {}

        if(callbackSuccess){
          callbackSuccess();
        }
        return res;


      }, (err) => {

        if(callbackFailed){
          callbackFailed(err);

        }
      });

  }

  // Create a new file from a capture image
  async saveImage(photo: any) {
    const base64Data = await this.readAsBase64(photo);
    // const fileName = new Date().getTime() + '.jpeg';
    // const savedFile = await Filesystem.writeFile({
    //   path: `${IMAGE_DIR}/${fileName}`,
    //   data: base64Data,
    //   directory: Directory.Data
    // });
    return base64Data;
  }

  async getImageAsBase64blob(imageURI) {
    const base64Data: any = await this.readAsBase64(imageURI);
    const response = await fetch(base64Data);
    const blob = await response.blob();
    return blob;
  }

  // https://ionicframework.com/docs/angular/your-first-app/3-saving-photos
  private async readAsBase64(imageUri: any) {

    if (imageUri.path) {
      //Upload from device
      const file = await Filesystem.readFile({
        path: (imageUri.path)
      });

      return `data:image/jpeg;base64,${file.data}`;
    }
    else if (imageUri.filepath) {
      //Upload from device
      const file = await Filesystem.readFile({
        path: (imageUri.filepath)
      });

      return `data:image/jpeg;base64,${file.data}`;
    }
    else if (this.plt.is('hybrid')) {
      //Upload from device simulator
      const file = await Filesystem.readFile({
        path: (imageUri.path)
      });

      return file.data;
    }
    else {
      //Upload from web mobile
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(imageUri.webPath);
      const blob = await response.blob();
      return await this.convertBlobToBase64(blob) as string;
    }
  }

  // Helper function
  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

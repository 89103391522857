import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ApplicationEvents } from 'src/app/infrastructure/events/application-events';
import { AlertServiceService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication-service.service';
import { DateService } from 'src/app/services/date-service.service';
import { EventService } from 'src/app/services/event.service';
import { MapPinService } from 'src/app/services/map-pin.service';
import { MapService } from 'src/app/services/map.service';
import { MixpanelService } from 'src/app/services/mixpanel-service.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-view-alert',
  templateUrl: './view-alert.page.html',
  styleUrls: ['./view-alert.page.scss'],
})
export class ViewAlertPage implements OnInit {
  w: any = window
  viewModel: any = {
    alert: null,
    address: null
  };

  @Input() alertId: any;
  @Input() isMapObject: any;


  constructor(private toastCtrl: ToastController, private authService: AuthenticationService, private eventService: EventService, private spinnerService: SpinnerService, private modalCtrl: ModalController, private dateService: DateService, private mixpanelService: MixpanelService, private mapService: MapService, private mapPinService: MapPinService, private alertsService: AlertServiceService) { }

  ngOnInit() {
    this.viewAlert();
  }

  viewAlert() {
    if (!this.isMapObject) {
      this.spinnerService.showLoading("Fetching recon details...");
    }
    this.alertsService.getAlert(this.alertId)
      .subscribe(async (alert:any) => {
        await this.spinnerService.dismiss();
        if (alert.analystAlertPriorityId == undefined) {
          alert.analystAlertPriorityId = 1;
        }
        this.viewModel.alert = alert;
        this.viewModel.address = alert.address;
        this.viewModel.alert.address = this.getAddress(alert);
        this.viewModel.alert.alertSourceName = this.getSourceName(alert);
        this.mixpanelService.trackViewAlertOrEmergency(this.viewModel.alert.isEmergency);
        this.addMap();
      }, async (error) => {

        await this.spinnerService.dismiss();
        if (error.status == 401) {
          this.toast('Unauthorized found 401 error');
          (await this.authService.refreshToken()).subscribe((result: any) => {
            this.authService.refreshUser(result.access_token, true, result.refresh_token, '/alerts');
            this.viewAlert();
          }, (error) => {

            //
            this.eventService.publishData({ eventName: ApplicationEvents.onUnauthorizedEvent });
          });
        }

      });
  }

  async toast(message){
     await this.toastCtrl.create({
       message: message,
       duration: 3000,
       position: 'top'
     })
  }


  dismissModal() {
    this.modalCtrl.dismiss();

  }

  public getDateTimeFormatted(date, format): string {
    return this.dateService.getDateTimeFormatted(new Date(date), format);
  }

  getAlertIcon(alert: any): string {
    return `assets/MapIcons/List/icn_alert_${alert.alertTypeCategoryId}_${alert.analystAlertPriorityId}.png`;
  }

  public getSourceName(alert): string {
    if (alert.source === 1) {
      return 'Scout Alert';
    }

    if (alert.source === 2) {
      return 'Analyst Alert';
    }

    return "-";
  }

  getAddress(alert) {
    return (alert.country && alert.city ? alert.country + ", " + alert.city : alert.addressFormatted);
  }



  async addMap() {
    let map = this.mapService.createMap('alertMap' + this.alertId, 8);
    let coords = {};

    if (this.viewModel.address.length > 0) {
      coords = [this.viewModel.address[0].long, this.viewModel.address[0].lat];
    }
    else if (this.viewModel.alert.addressFormatted) {
      let address = await this.mapService.getCoordsBasedOn(this.viewModel.alert.addressFormatted).toPromise();
      coords = [address.longitude, address.latitude];
    }
    map.setCenter(coords);
    this.addMarker(coords, map);

    map.on('load', () => {
      this.mapPinService.clearCluster(map, "alert-cluster");
      let feature = this.mapPinService.getFeature(coords,
        this.viewModel.alert.isEmergency ? 'map_pin_red' : 'map_pin_orange',
        { width: 17, height: 28 },
        { x: -8, y: -28 });
      let featureCollection = this.mapPinService.getFeatureCollection([feature]);
      this.mapPinService.createCluster(map, "alert-cluster", featureCollection, "#51bbd6");
    });

    //this.mapService.setMapLayers(map, ViewAlert.pageName); todo mapbox gl
  }


  addMarker(coords, map) {
    let marker = new this.w.mapboxgl.Marker()
      .setLngLat(coords).setPopup(new this.w.mapboxgl.Popup({ offset: 25 }).setText(this.viewModel.alert.address))
    marker.addTo(map);

  }

}

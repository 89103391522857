import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { AuthenticationService } from "./authentication-service.service";
import { NotificationService } from "./notification.service";
import { AppSettings } from "../app-settings";
import { environment } from "src/environments/environment";


@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor() {}

  private notification: NotificationService = inject(NotificationService);
  private authService: AuthenticationService = inject(AuthenticationService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): any {

    console.log(req.url);

    if(req.body instanceof FormData){
      console.log('Form file found');
      req = req.clone({
        headers: getHeadersForMultipartFormFile(),
      });
    }
    else{
      req = req.clone({
        headers: getDefaultHeaders(req),
      });
    }
    req = req.clone({ url:  this.getAbsoluteUrl(req.url) });
    // return next.handle(req).pipe(
    //   tap({
    //     next: (r:any )=> {
    //       if(!environment.production && req.method=='POST'){
    //         console.log(r.body)
    //       }
    //     },
    //     error: e => {this.handleError(req,next,e)}
    //   })
    // );
    return next.handle(req).pipe(
      catchError((error) => {
        // Check if the error is due to an expired access token
        if (error.status === 401 ) {
          return this.handleTokenExpired(req, next);
        }
        return throwError(error);
      })
    );
  }

  handleError(req:  HttpRequest<unknown>, next: HttpHandler, error: HttpErrorResponse)  {

    let statusCode = error.status;
    let errorMsg =
      (error.error == null ? error.message : error.error.msg) ||
      'Something went wrong.';
    if (statusCode == 0) {
      errorMsg =
        'You may have internet connection problem. Check your network and try again.';
    } else if (statusCode == 404) {
      errorMsg =
        'You may have application issues. Please contact with system admin.';
    } else if (statusCode == 401 || statusCode == 403) {
      errorMsg = 'You are not authorized. Please contact with system admin.';
      return this.handle401Error(req,next,error);
    } else if (statusCode == 400) {
      errorMsg =

         '' +
         error?.error?.errorMessage[0];
    }
    //showing message
    if (errorMsg != '') {
      this.notification.presentToast(errorMsg);
    }
    return error;
  };

 handle401Error(req: HttpRequest<unknown>, next: HttpHandler, error: HttpErrorResponse) {
   return this.handleTokenExpired(req, next);
 }


 handleTokenExpired(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
  // Call the refresh token endpoint to get a new access token
  console.log('handleTokenExpired()');
  return this.authService.refreshToken().pipe(
    switchMap((data:any) => {
      console.log('Inside switchMap',data);
      this.authService.refreshUser(data.access_token,true,data.refresh_token);
      // Retry the original request with the new access token
      return next.handle(request.clone({headers: getDefaultHeaders(request)}));
    }),
    catchError((error) => {
      console.log('Inside catchError')
      // Handle refresh token error (e.g., redirect to login page)
      console.error('Error handling expired access token:', error);
      this.notification.presentToast('Failed to refresh the token, Pease login again');
      return throwError(error);
    })
  );
}

private getAbsoluteUrl(relativePath: string): string {
    if(relativePath.includes('https://')){
      return relativePath;
    }
    return `${AppSettings.webApiRootUrl}${relativePath}`;
}

}

function getDefaultHeaders(req: HttpRequest<unknown>) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append('Content-Type', 'application/json');
  headers = headers.append('Accept', 'application/json');
  let url = req.url;
  if (url == 'oauth/token' || url == 'api/Settings/ChangeUserAlertsNotificationsShow' ||
    url == 'api/Settings/SetUserAlertsNotificationsInterval' ||
    url == 'api/Settings/SetUserAlertsNotificationsMaxNumber' ||
    url == 'api/Settings/SetEmergencyNotificationPhone' ||
    url == 'api/Settings/SetEmergencyNotificationEmail' ||
    url == 'api/Settings/SetUserMapZoom') {
    return getDefaultHeadersForOauth();
  }

  if (AuthenticationService.authenticationToken) {
    headers = headers.append('Authorization', 'Bearer ' + AuthenticationService.authenticationToken);
  }
  else{
    AuthenticationService.authenticationToken = localStorage. getItemItem('accessToken') || "";
    headers = headers.append('Authorization', 'Bearer ' + AuthenticationService.authenticationToken)
  }
  return headers;
}

function getDefaultHeadersForOauth() {
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
  return headers;
}

function getHeadersForMultipartFormFile() {
  const header  = new HttpHeaders({
    'Accept': '*/*',
    'Authorization':
    "Bearer "+AuthenticationService.authenticationToken,
    'Access-Control-Allow-Origin': `${environment.webApiRootUrl}/*`,
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
    'Access-Control-Allow-Credentials': 'true'
  });
  return header;
}
